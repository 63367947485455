enum ServiceSubscriptionEnum {
    PART_MATCHING = 'PART_MATCHING',
    NEW_PARTS = 'NEW_PARTS',
    NEW_MANUFACTURER_ITEMS = 'NEW_MANUFACTURER_ITEMS',
    NEW_COMPANY_ITEMS = 'NEW_COMPANY_ITEMS',
    PART_REQUEST = 'PART_REQUEST',
    ITEM_MONITORING = 'ITEM_MONITORING',
    EXTRACTION = 'EXTRACTION',
    ONE_TIME_EXTRACTION = 'ONE_TIME_EXTRACTION',
    RECURRING_EXTRACTION = 'RECURRING_EXTRACTION',
    SUBSCRIBE_INFORMATION_PACKAGE = 'SUBSCRIBE_INFORMATION_PACKAGE',
    CREATE_PRIVATE_ATTRIBUTES = 'CREATE_PRIVATE_ATTRIBUTES',
    UPDATE_PRIVATE_ATTRIBUTES = 'UPDATE_PRIVATE_ATTRIBUTES',
    CAD_MODEL = 'CAD_MODEL',
}

const serviceNames = {
    [ServiceSubscriptionEnum.PART_MATCHING]: {
        title: 'Item Matching'
    },
    [ServiceSubscriptionEnum.NEW_PARTS]: {
        title: 'New Items'
    },
    [ServiceSubscriptionEnum.NEW_MANUFACTURER_ITEMS]: {
        title: 'Create Manufacturer Items'
    },
    [ServiceSubscriptionEnum.NEW_COMPANY_ITEMS]: {
        title: 'Create Company Items'
    },
    [ServiceSubscriptionEnum.EXTRACTION]: {
        title: 'Item Extractions'
    },
    [ServiceSubscriptionEnum.ONE_TIME_EXTRACTION]: {
        title: 'One-Time Extraction'
    },
    [ServiceSubscriptionEnum.RECURRING_EXTRACTION]: {
        title: 'Recurring Extraction'
    },
    [ServiceSubscriptionEnum.SUBSCRIBE_INFORMATION_PACKAGE]: {
        title: 'Access to Item Data'
    },
    [ServiceSubscriptionEnum.CREATE_PRIVATE_ATTRIBUTES]: {
        title: 'Manage Private Attributes Creation'
    },
    [ServiceSubscriptionEnum.UPDATE_PRIVATE_ATTRIBUTES]: {
        title: 'Manage Private Attributes Update'
    },
    [ServiceSubscriptionEnum.CAD_MODEL]: {
        title: 'Create CAD Model'
    },
    [ServiceSubscriptionEnum.ITEM_MONITORING]: {
        title: 'Item Monitoring'
    }
}

export const getServiceName = (value: ServiceSubscriptionEnum | string): string => {
    return (serviceNames as any)[value]?.title ?? ''
}

export default ServiceSubscriptionEnum
