import React from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import App from './App'
import {ROUTES} from './util/constants/routing'
import ActiveGuildRequestTable from './Guilds/ActiveGuildsTable/ActiveGuildsTable'
import EditGuild from './Guilds/EditGuild/EditGuild'
import AutoSignInPage from './authentication/AutoSignInPage'
import SecuredApp from './templates/SecuredAppTemplate/SecuredApp'
import GuildRequestTable from './Guilds/GuildRequests/GuildRequestsTable'
import ServiceRequestsTable from './ServiceRequests/ServiceRequestsTable/ServiceRequestsTable'
import EditQuotation from './ServiceRequests/ServiceRequestsTable/UpdateQuotation/EditQuotation'
import BannerTable from './Banner/BannerTable/BannerTable'
import CreateBanner from './Banner/BannerActions/CreateBanner'
import EditBanner from './Banner/BannerActions/EditBanner'
import NewsTable from './News/NewsTable/NewsTable'
import CreateNews from './News/NewsActions/CreateNews'
import EditNews from './News/NewsActions/EditNews'
import {DisplaySetsStructureUpdate} from './displaySets/structure-update/displaySetsStructureUpdate'
import {
    InformationPackagesStructureUpdate
} from './informationPackages/structure-update/informationPackagesStructureUpdate'
import {DisplaySetEdit} from './displaySets/edit/displaySetEdit'
import {InformationPackageEdit} from './informationPackages/edit/informationPackageEdit'
import {
    PreferredDefinitionsStructureUpdate
} from './preferredDefinitions/structure-update/preferredDefinitionsStructureUpdate'


const createRoutes = () => {
    return (
        <App>
            <Routes>
                <Route
                    path={ROUTES.login.path}
                    element={<AutoSignInPage/>}
                />

                <Route element={<SecuredApp/>}>
                    <Route
                        path={ROUTES.root.path}
                        element={<Navigate to={ROUTES.guildRequest.path ?? ''}/>}
                    />
                    <Route
                        path={ROUTES.guildRequest.path}
                        element={<GuildRequestTable/>}
                    />
                    <Route
                        path={ROUTES.activeGuilds.path}
                        element={<ActiveGuildRequestTable/>}
                    />
                    <Route
                        path={ROUTES.editGuild.path + ':guildId'}
                        element={<EditGuild/>}
                    />

                    <Route
                        path={ROUTES.serviceRequest.path}
                        element={<ServiceRequestsTable/>}
                    />
                    <Route
                        path={ROUTES.serviceRequestQuotation.path + ':serviceRequestId'}
                        element={<EditQuotation/>}
                    />
                    <Route
                        path={ROUTES.banner.path}
                        element={<BannerTable/>}
                    />
                    <Route
                        path={ROUTES.editBanner.path}
                        element={<EditBanner/>}
                    />
                    <Route
                        path={ROUTES.createBanner.path}
                        element={<CreateBanner/>}
                    />
                    <Route
                        path={ROUTES.news.path}
                        element={<NewsTable/>}
                    />
                    <Route
                        path={ROUTES.createNews.path}
                        element={<CreateNews/>}
                    />
                    <Route
                        path={ROUTES.editNews.path}
                        element={<EditNews/>}
                    />
                    <Route
                        path={ROUTES.displaySet.path}
                        element={<DisplaySetsStructureUpdate/>}
                    />
                    <Route
                        path={ROUTES.displaySetNew.path}
                        element={<DisplaySetEdit/>}
                    /><Route
                    path={ROUTES.displaySetEdit.path}
                    element={<DisplaySetEdit/>}
                />
                    <Route
                        path={ROUTES.informationPackage.path}
                        element={<InformationPackagesStructureUpdate/>}
                    />
                    <Route
                        path={ROUTES.informationPackageNew.path}
                        element={<InformationPackageEdit/>}
                    />
                    <Route
                        path={ROUTES.informationPackageEdit.path}
                        element={<InformationPackageEdit/>}
                    />
                    <Route
                        path={ROUTES.preferredDefinitions.path}
                        element={<PreferredDefinitionsStructureUpdate/>}
                    />
                </Route>

            </Routes>
        </App>
    )
}

export default <BrowserRouter children={createRoutes()}/>
