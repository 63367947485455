import './App.scss'
import {Helmet} from 'react-helmet'
import {useMatchedRoute} from './util/routing'
import {RequestInterceptor} from './util/axios/axiosInterceptor'
import Header from './Header/Header'
import {Snackbar} from './util/SnackBar/Snackbar'

const App: React.FC<any> = ({children}) => {
  const DEFAULT_PORTAL_TITLE = 'DATX Admin'
  const routes = useMatchedRoute()
  const pageTitle = routes?.[0]?.route.options?.pageTitle
  const getPageHeader = () => {
    const header = DEFAULT_PORTAL_TITLE
    if(!pageTitle) return header

    return  header + ' - ' + pageTitle
  }
  

  return (
      <RequestInterceptor>
        <div className="main-app-container" data-testid="main-app-container">
          <Helmet>
            <title>{getPageHeader()}</title>
          </Helmet>
          <Header></Header>
          {children}
          <Snackbar/>
        </div>
      </RequestInterceptor>
  )
}

export default App
