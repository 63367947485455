import React from 'react'
import './Header.scss'
import {AppBar} from '@mui/material'
import Logo from './menuComponents/Logo'
import UserMenu from './menuComponents/UserMenu'
import GuildMenu from './menuComponents/GuildMenu'
import ServiceRequestMenu from './menuComponents/ServiceRequestMenu'
import {AuthenticatedTemplate} from '@azure/msal-react'
import ManagementMenu from './menuComponents/ManagementMenu'
import StructureMenu from './menuComponents/StructureMenu'

const NavigationHeader = () => (
    <div className="navigation-header">
        <GuildMenu/>
        <ServiceRequestMenu/>
        <ManagementMenu/>
        <StructureMenu/>
        <UserMenu/>
    </div>
)

const Header = () => {
    return (
        <AppBar className="main-header" id="main-header">
            <Logo/>
            <AuthenticatedTemplate>
                <NavigationHeader/>
            </AuthenticatedTemplate>
        </AppBar>
    )
}

export default Header
