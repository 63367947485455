import {RouteObject} from 'react-router-dom'

export interface RouteTypeDic {
    [x: string]: RouteObject & RouteCustomType
}

export interface RouteCustomType {
    options?: { pageTitle?: string,hideSearch?:boolean  }
}

// If useRoutes will be used later to setup routes,
// this object can be modified into array for that purpose.
export const ROUTES: RouteTypeDic = {
    login: {path: '/login'},
    root: {path: '/'},
    guildRequest: {path: '/guilds/requests'},
    activeGuilds: {path: '/guilds/actives'},
    editGuild: {path: '/guilds/editGuild/'},

    serviceRequest: {path: '/services/request'},
    serviceRequestQuotation: {path: '/services/request/quotation/'},

    displaySet: {path: '/portal-structure/display-sets'},
    displaySetNew: {path: '/portal-structure/display-sets/new'},
    displaySetEdit: {path: '/portal-structure/display-sets/:displaySetId'},
    informationPackage: {path: '/portal-structure/information-packages'},
    informationPackageNew: {path: '/portal-structure/information-packages/new'},
    informationPackageEdit: {path: '/portal-structure/information-packages/:informationPackageId'},
    preferredDefinitions: {path: '/portal-structure/preferred-definitions'},
    banner: {path: '/portal-management/banners'},
    editBanner: {path: '/portal-management/banners/editBanner/:bannerId'},
    createBanner: {path: '/portal-management/banners/createBanner/'},
    news: {path: '/portal-management/news'},
    editNews: {path: '/portal-management/news/editNews/:newsId'},
    createNews: {path: '/portal-management/news/createNews'}
}